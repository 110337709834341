module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-VHQV34BVYH"],"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SaVa.Team","short_name":"SaVa.Team","description":"SaVa.Team специализируется на широком спектре технологий и услуг в области разработки и дизайне, включая e-Commerce, React, Node, Python, Go, блокчейн, разработку игр, искусственный интеллект, бизнес-аналитику, компьютерное зрение, машинное обучение, обработку естественного языка, а также решения для iOS, Android, Magento и Shopify. Наша команда экспертов предоставляет передовые решения, адаптированные под потребности вашего бизнеса.","start_url":"/","background_color":"#4a47a3","theme_color":"#4a47a3","display":"standalone","icon":"static/logo1024.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d82c37a507e9185d7f34c99893396c16"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://sava.team"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mailgo/gatsby-browser.js'),
      options: {"plugins":[],"mailgoConfig":{"dark":false,"actions":{"skype":false}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-yandex-metrika/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"96970331","accurateTrackBounce":true,"trackLinks":true,"webvisor":true,"trackHash":true,"afterBody":true,"defer":false,"clickmap":true},
    },{
      plugin: require('../gatsby-browser.jsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
